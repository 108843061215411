@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 27 27 27;
    --foreground: 225 225 225;
    --muted: 115 115 115;
    --accent: 254 254 91;
    /* hex value of rgb(254,254,91) color is  #FEFE5B  */
  }
}

html {
  scroll-behavior: smooth;
}

@layer utilities {
  .pause {
    animation-play-state: paused;
  }

  .custom-bg {
    @apply bg-background/20 border border-accent/30 border-solid backdrop-blur-[6px] shadow-glass-inset hover:shadow-glass-sm;
  }
}

@keyframes move {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(100px, 100px);
  }
}

body {
  cursor: none;
}

a, button, [role="button"], [type="button"], [type="submit"] {
  cursor: none;
}